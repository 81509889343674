@import "variables";
@import "general";
@import "_media_query";

//colors

.primary {
    background-color: $primaryColor;
    border-color: $primaryColor;
}

.secondary {
    background-color: $secondaryColor;
    border-color: $secondaryColor;
}

.Success {
    background-color: $thirdColor;
    border-color: $thirdColor;
}

.danger {
    background-color: $fourthColor;
    border-color: $fourthColor;
}

.warning {
    background-color: $secondaryColor;
    border-color: $secondaryColor;
}

.info {
    background-color: $primaryColor;
    border-color: $primaryColor;
}
.light {
    background-color: $fifthColor;
    border-color: $fifthColor;
}

//Seccion width
.w-90 {
    width: 95%;
}

//Seccion margenes
.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.m-auto {
    margin: auto;
}

.m-1 {
    margin: 1rem;
}

.m-2 {
    margin: 2rem;
}

.m-3 {
    margin: 3rem;
}

.m-4 {
    margin: 4rem;
}

.m-5 {
    margin: 5rem;
}

.m-6 {
    margin: 6.5rem;
}

.m-7 {
    margin: 7rem;
}

.m-8 {
    margin: 8rem;
}

.m-9 {
    margin: 9rem;
}

.m-10 {
    margin: 10rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mt-4 {
    margin-top: 4rem;
}

.mt-5 {
    margin-top: 5rem;
}

.mt-6 {
    margin-top: 6rem;
}

.mt-7 {
    margin-top: 7rem;
}

.mt-8 {
    margin-top: 8rem;
}

.mt-9 {
    margin-top: 9rem;
}

.mt-10 {
    margin-top: 10rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

.mb-5 {
    margin-bottom: 5rem;
}

.mb-6 {
    margin-bottom: 6rem;
}

.mb-7 {
    margin-bottom: 7rem;
}

.mb-8 {
    margin-bottom: 8rem;
}

.mb-9 {
    margin-bottom: 9rem;
}

.mb-10 {
    margin-bottom: 10rem;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.ml-3 {
    margin-left: 3rem;
}

.ml-4 {
    margin-left: 4rem;
}

.ml-5 {
    margin-left: 5rem;
}

.ml-6 {
    margin-left: 6rem;
}

.ml-7 {
    margin-left: 7rem;
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mr-3 {
    margin-right: 3rem;
}

.mr-4 {
    margin-right: 4rem;
}

.mr-5 {
    margin-right: 5rem;
}

//Seccion padding

.py-auto {
    padding-top: auto;
    padding-bottom: auto;
}

.px-auto {
    padding-left: auto;
    padding-right: auto;
}

.p-auto {
    padding: auto;
}

//Seccion displays
.grid {
    display: grid;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
}

//Seccion distancia entre elementos

.grid-gap-1 {
    gap: 1rem;
}

.grid-gap-2 {
    gap: 2rem;
}

.grid-gap-3 {
    gap: 3rem;
}

//Seccion contentido
.content-center {
    align-items: start;
    justify-items: center;
    align-self: center;
    justify-self: center;
}

.content-right {
    align-items: end;
    justify-items: end;
    align-self: center;
    justify-self: end;
}

.content-left {
    align-items: start;
    justify-items: start;
    align-self: center;
    justify-self: start;
}

//Seccion texto
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}
