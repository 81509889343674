@media (max-width: 768px) {
  .sm-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm-content-center {
    align-items: start;
    justify-items: center;
    align-self: center;
    justify-self: center;
  }
  .sm-content-left {
    align-items: start;
    justify-items: start;
    align-self: center;
    justify-self: start;
  }
  .sm-content-right {
    align-items: end;
    justify-items: end;
    align-self: center;
    justify-self: end;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .md-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1025px) {
  .lg-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg-m-6 {
    margin: 6.5rem;
  }
  .lg-grid-gap-3 {
    gap: 4rem;
  }
  .lg-content-center {
    align-items: start;
    justify-items: center;
    align-self: center;
    justify-self: center;
  }
  .lg-content-left {
    align-items: start;
    justify-items: start;
    align-self: center;
    justify-self: start;
  }
  .lg-content-right {
    align-items: end;
    justify-items: end;
    align-self: center;
    justify-self: end;
  }
}
.primary {
  background-color: #2e5fd6;
  border-color: #2e5fd6;
}

.secondary {
  background-color: #000000;
  border-color: #000000;
}

.Success {
  background-color: #f37121;
  border-color: #f37121;
}

.danger {
  background-color: #d92027;
  border-color: #d92027;
}

.warning {
  background-color: #000000;
  border-color: #000000;
}

.info {
  background-color: #2e5fd6;
  border-color: #2e5fd6;
}

.light {
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.w-90 {
  width: 95%;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-auto {
  margin: auto;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.m-4 {
  margin: 4rem;
}

.m-5 {
  margin: 5rem;
}

.m-6 {
  margin: 6.5rem;
}

.m-7 {
  margin: 7rem;
}

.m-8 {
  margin: 8rem;
}

.m-9 {
  margin: 9rem;
}

.m-10 {
  margin: 10rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.mb-7 {
  margin-bottom: 7rem;
}

.mb-8 {
  margin-bottom: 8rem;
}

.mb-9 {
  margin-bottom: 9rem;
}

.mb-10 {
  margin-bottom: 10rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.ml-6 {
  margin-left: 6rem;
}

.ml-7 {
  margin-left: 7rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}

.p-auto {
  padding: auto;
}

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-gap-1 {
  gap: 1rem;
}

.grid-gap-2 {
  gap: 2rem;
}

.grid-gap-3 {
  gap: 3rem;
}

.content-center {
  align-items: start;
  justify-items: center;
  align-self: center;
  justify-self: center;
}

.content-right {
  align-items: end;
  justify-items: end;
  align-self: center;
  justify-self: end;
}

.content-left {
  align-items: start;
  justify-items: start;
  align-self: center;
  justify-self: start;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}/*# sourceMappingURL=styles.css.map */