@import "../../../assets/styles/variables";

.site-header-main-logo-hb {
    display: none;
    margin: 0.5rem;
    @media (max-width: 580px) {
        margin: auto;
        display: grid;
        grid-template-columns: 1fr;
    }
}

//barra donde estan los items
.site-navigation-wrapper {
    .navbar {
        height: 6vh;
        .items {
            grid-template-columns: 20% 80%;
            .hb-bar {
                display: none;
            }
            .links {
                font-size: 1.3rem;
                margin-top: 1.2rem;
                .nav-item {
                    text-align: center;
                    list-style: none;
                    .links-text {
                        color: $primaryFontColor;
                        text-decoration: none;
                        margin: 2.5rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .grid {
        width: 100%;
        flex-direction: column;
    }
    .grid-cols-2 {
        grid-template-columns: 1fr;
    }
    .site-header-main-item {
        justify-self: center !important;
        .nav-item {
            text-align: center;
            display: grid;
            grid-template-columns: 1fr;
        }
    }
    .site-navigation-wrapper .navbar {
        height: auto !important;
    }
    .site-navigation-wrapper .navbar .items {
        width: 100%;
        grid-template-columns: 68% 24% !important;
        .hb-bar {
            margin-right: 5%;
            display: block;
        }
    }
    .site-navigation-wrapper .navbar .items .links {
        display: none;
    }
    .site-navigation-wrapper .navbar .items .links-show {
        justify-self: center;
        display: block;
        font-size: 1.3rem;
        margin-left: 21%;
        .nav-item {
            display: grid;
            grid-template-columns: 1fr;
            text-align: center;
            .links-text {
                color: $primaryFontColor;
                text-decoration: none;
                margin: auto;
                padding: 0.5rem;
                list-style: none;
            }
        }
    }
}
