$primaryColor: #2e5fd6;
$secondaryColor: #000000;
$thirdColor: #f37121;
$fourthColor: #d92027;
$fifthColor: #f7f7f7;
$primaryFontColor: rgb(0, 0, 0);
$secondaryFontColor: rgb(255, 255, 255);
$principalFont: "DM Sans", sans-serif;
$fontSizePhone: 2.5em;
$fontSizeTitles: 3em;
$fontSizeSubTitles: 2em;
$fontSizeSubHeadLine: 1em;
$fontSizeText: 1em;
