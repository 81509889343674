footer {
    background-color: black;
}

.footer-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row dense;
    grid-template-areas:
        "top-row"
        "bottom-row";
}

.top-row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "logo"
        "social";
    grid-area: top-row;

    .logo {
        grid-area: logo;
    }

    .social {
        grid-area: social;
    }

    .bottom-row {
        grid-area: bottom-row;
    }
}

.bottom-row{
    border-top: 1px solid #b6b6b9;
}

.info-items ul {
    display: grid;
    grid-template-columns: 23% 9% 10% 10%;
    gap: 1rem;
    color: #b6b6b9;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    li a {
        text-decoration: none;
        color: #b6b6b9;
    }
}
