.title {
    font-size: clamp(1.2rem, 2vw, 5rem); //minimo, estable, maximo
}

.subtitle {
    font-size: clamp(1rem, 1vw, 5rem); //minimo, estable, maximo
}

.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "izquierda derecha"
        "izquierda derecha";

    .izquierda {
        grid-area: izquierda;
    }

    .derecha {
        grid-area: derecha;
    }
}

.image-container {
    img {
        max-width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
            "izquierda"
            "derecha";
    }
}

.bullet-point-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "izquierda derecha";

    .izquierda {
        grid-area: izquierda;
    }

    .derecha {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 0.5fr 1.5fr 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
            "description description description"
            "bullets- bullets- bullets-"
            "bullets- bullets- bullets-";
        grid-area: derecha;
    }

    .description {
        grid-area: description;
    }

    .bullets {
        grid-area: bullets-;
    }
}

.centered-testimonials {
    display: grid;
    grid-template-columns: 0.4fr 2.2fr 0.4fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "izquierda centro derecha";
    .centro {
        grid-area: centro;
    }
}

@media screen and (max-width: 768px) {
    .bullet-point-container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
            "izquierda"
            "derecha";
    }
}

@media screen and (max-width: 768px) {
    .centered-testimonials {
        grid-template-columns: 0.4fr 2.2fr 0.4fr;
    }
}

@media screen and (max-width: 768px) {
    .faq-container {
        grid-template-columns: 1fr;
        
    }
}

